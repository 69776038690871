import React, { useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner
} from "reactstrap";
import {
  createLogEntry,
  getLogEntry,
  updateLogEntry
} from "../services/logService";
import AssocTable from "./AssocTable";
import { updateField } from "../services/fieldsService";
import { phraseToProperCase } from "../libs/case-utils";
import { v4 as uuidv4 } from "uuid";
import Moment from "react-moment";
import "moment-timezone";
import classnames from "classnames";
import "./DataField.css";
import {ReversedFileTypeAbbr} from "../utils/appUtils";

const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;

// Set the timezone for every instance.
Moment.globalTimezone = "America/Detroit";

// Set the output format for every react-moment instance.
Moment.globalFormat = "MM/DD/YYYY HH:mm:ss";

const splitLastOccurrence = (str, substring) => {
  const lastIndex = str.lastIndexOf(substring);

  const before = str.slice(0, lastIndex);

  const after = str.slice(lastIndex + 1);

  return [before, after];
};

const DataField = (props) => {
  const { field: defField, user } = props;

  const [currentField, setCurrentField] = useState(defField);
  const [isStaged, setIsStaged] = useState(!currentField.staged ? false : true);
  // const [fieldPart, sourcePart] = currentField.fieldKey.split("_", 2);
  const [fieldPart, sourcePart] = splitLastOccurrence(
    currentField.fieldKey,
    "_"
  );
  const isOptions = currentField.validation.options ? true : false;

  const userInfo = {
    id: user.id,
    name: user.name,
    email: user.email
  };

  // Control tabs
  const [activeTab, setActiveTab] = useState("1");
  const toggleTabs = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const getFieldSet = (abbr) => {
    return ReversedFileTypeAbbr[abbr];
  };

  const fieldset = getFieldSet(sourcePart);
  const isOhPackage = fieldset === "poletopInspection" && currentField.editable;
  const isReinforcementAudit = fieldset === "poleReinforcementAudit";

  const CreatedByField = () => {
    const { createdBy, createdAt } = currentField;
    const name = createdBy ? createdBy.name : "Unknown";

    return (
      <>
        <b>Created by:</b>
        <br /> {name} {" @ "}
        <Moment>{createdAt}</Moment>
      </>
    );
  };

  const UpdatedByField = () => {
    const { updatedBy, updatedAt } = currentField;
    const name = updatedBy ? updatedBy.name : "Unknown";

    return (
      <>
        <b>Updated by:</b>
        <br /> {name} {" @ "}
        <Moment>{updatedAt}</Moment>
      </>
    );
  };

  const PoleReinforcementQaTab = (props) => {
    const { fieldKey, qa: defQa, handleCurrentFieldUpdate, isStaged } = props;

    return (
      <TabPane tabId="4">
        <CardBody>
          <Json
            data={{ fieldKey, defQa, handleCurrentFieldUpdate, isStaged }}
          />
        </CardBody>
      </TabPane>
    );
  };

  const MappingTab = (props) => {
    const {
      fieldKey,
      aliases: defAliases,
      handleCurrentFieldUpdate,
      isStaged
    } = props;

    const [aliases, setAliases] = useState(defAliases);
    const [aliasErrors, setAliasErrors] = useState({});
    const [overridesErrors, setOverridesErrors] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const sources = Object.keys(aliases);

    const EditButton = (props) => {
      const { handleEdit } = props;

      const color = isStaged ? "warning" : "success";

      return (
        <Button
          outline
          color={color}
          size="sm"
          className="mt-1rem"
          onClick={() => handleEdit(true)}
        >
          Edit
        </Button>
      );
    };

    const SaveCancelButtons = (props) => {
      const { handleSave, handleCancel, isLoading } = props;

      const isErrors = Object.keys(aliasErrors).length > 0;

      const color = isStaged ? "warning" : "success";

      return (
        <>
          <Button
            className="mt-1rem"
            color={color}
            size="sm"
            onClick={handleSave}
            disabled={isErrors || isLoading ? true : false}
          >
            Save changes {isLoading && <Spinner size="sm" color="light" />}
          </Button>
          <Button
            className="mt-1rem"
            color="default"
            size="sm"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </>
      );
    };

    const validateAlias = (source, alias) => {
      const aliasErrorsCopy = { ...aliasErrors };

      const maxLength = 64;

      // Check max length
      if (alias.length > maxLength) {
        aliasErrorsCopy[
          source
        ] = `${source} alias value is too long. Current length is ${alias.length} characters. Maximum length is ${maxLength} characters`;
        setAliasErrors(aliasErrorsCopy);

        return false;
      }

      // Check format
      if (!/^[A-Za-z0-9_-]*$/.test(alias)) {
        aliasErrorsCopy[source] = `Invalid characters in ${source} alias value`;
        setAliasErrors(aliasErrorsCopy);

        return false;
      }

      setAliasErrors({});

      return true;
    };

    const validateOverrides = (source, overrides) => {
      const overridesErrorsCopy = { ...overridesErrors };

      // Check format
      if (!/^[A-Za-z0-9_,]*$/.test(overrides)) {
        overridesErrorsCopy[
          source
        ] = `Invalid characters in ${source} overrides value`;
        setOverridesErrors(overridesErrorsCopy);

        return false;
      }

      setOverridesErrors({});

      return true;
    };

    const validateForm = () => {
      let test = true;

      for (let i = 0; i < sources.length; i++) {
        if (aliases[sources[i]].field) {
          test = validateAlias(sources[i], aliases[sources[i]].field);

          if (!test) return false;
        }

        if (aliases[sources[i]].overrides) {
          test = validateOverrides(sources[i], aliases[sources[i]].overrides);

          if (!test) return false;
        }
      }

      return true;
    };

    const handleSave = async () => {
      if (!validateForm()) return false;

      setIsLoading(true);

      const updateResult = await updateField(
        { fieldKey },
        { alias: aliases, updatedBy: { ...userInfo } }
      );

      if (updateResult.modifiedCount) {
        setIsLoading(false);
        setIsEditing(false);

        // Update field state
        const currentFieldCopy = { ...currentField };
        currentFieldCopy.alias = aliases;
        currentFieldCopy.updatedAt = updateResult.updatedAt;
        currentFieldCopy.updatedBy = { ...userInfo };

        handleCurrentFieldUpdate(currentFieldCopy);
      }
    };

    const handleCancel = () => {
      setAliases(defAliases);
      setIsEditing(false);
    };

    const handleAlias = (source, alias) => {
      if (validateAlias(source, alias)) {
        const aliasesCopy = { ...aliases };

        aliasesCopy[source]["field"] = alias;

        setAliases(aliasesCopy);
      }
    };

    const handleOverrides = (source, overrides) => {
      if (validateOverrides(source, overrides)) {
        const aliasesCopy = { ...aliases };

        aliasesCopy[source]["overrides"] = overrides.length
          ? overrides.split(",")
          : [];

        setAliases(aliasesCopy);
      }
    };

    return (
      <TabPane tabId="3">
        <CardBody>
          <Form>
            <Card body>
              {!isEditing &&
                sources.map((source, i) => {
                  const isOverrides = aliases[source].overrides ? true : false;
                  const overrides = isOverrides
                    ? aliases[source].overrides
                    : [];

                  return (
                    <Card key={i} className="mb-1rem">
                      <CardHeader>{source}</CardHeader>
                      <CardBody>
                        <CardText>
                          <b>Field alias:</b>{" "}
                          {aliases[source].field
                            ? aliases[source].field
                            : "none set"}
                        </CardText>
                        <CardText>
                          <b>Value overrides:</b>{" "}
                          {isOverrides && overrides.length
                            ? overrides.join(",")
                            : "none set"}
                        </CardText>
                      </CardBody>
                    </Card>
                  );
                })}
              {isEditing &&
                sources.map((source, i) => {
                  const isOverrides = aliases[source].overrides ? true : false;
                  const overrides = isOverrides
                    ? aliases[source].overrides
                    : [];

                  return (
                    <Card key={i}>
                      <CardHeader>{source}</CardHeader>
                      <CardBody>
                        <>
                          <CardText>
                            <b>Field alias</b>
                          </CardText>
                          <FormGroup className="mb-1rem">
                            <Input
                              invalid={aliasErrors[source] ? true : false}
                              type="text"
                              id={`field-alias-${source}`}
                              defaultValue={
                                aliases[source].field
                                  ? aliases[source].field
                                  : ""
                              }
                              disabled={isLoading}
                              onChange={(e) =>
                                handleAlias(source, e.target.value)
                              }
                            />
                            {aliasErrors[source] && (
                              <FormFeedback>{aliasErrors[source]}</FormFeedback>
                            )}
                          </FormGroup>
                          <CardText>
                            <b>Value overrides</b>
                          </CardText>
                          <FormGroup className="mb-1rem">
                            <Input
                              invalid={overridesErrors[source] ? true : false}
                              type="text"
                              id={`field-overrides-${source}`}
                              defaultValue={
                                isOverrides && overrides.length
                                  ? overrides.join(",")
                                  : ""
                              }
                              disabled={isLoading}
                              onChange={(e) =>
                                handleOverrides(source, e.target.value)
                              }
                            />
                            {overridesErrors[source] && (
                              <FormFeedback>
                                {overridesErrors[source]}
                              </FormFeedback>
                            )}
                          </FormGroup>
                        </>
                      </CardBody>
                    </Card>
                  );
                })}
              {/* <Json data={aliases} /> */}
            </Card>
            {!isEditing && <EditButton handleEdit={setIsEditing} />}
            {isEditing && (
              <SaveCancelButtons
                handleSave={handleSave}
                handleCancel={handleCancel}
                isLoading={isLoading}
              />
            )}
          </Form>
        </CardBody>
      </TabPane>
    );
  };

  const DataFieldTab = (props) => {
    const { isStaged, fieldKey, fieldDescription, handleCurrentFieldUpdate } =
      props;

    const [description, setDescription] = useState(fieldDescription);
    const [descriptionError, setDescriptionError] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const EditButton = (props) => {
      const { handleEdit, isStaged } = props;

      const color = isStaged ? "warning" : "success";

      return (
        <Button
          outline
          color={color}
          size="sm"
          className="mt-1rem"
          onClick={() => handleEdit(true)}
        >
          Edit
        </Button>
      );
    };

    const SaveCancelButtons = (props) => {
      const { handleSave, handleCancel, isLoading, isStaged } = props;

      const color = isStaged ? "warning" : "success";

      return (
        <>
          <Button
            className="mt-1rem"
            color={color}
            size="sm"
            onClick={handleSave}
            disabled={descriptionError || isLoading ? true : false}
          >
            Save changes {isLoading && <Spinner size="sm" color="light" />}
          </Button>
          <Button
            className="mt-1rem"
            color="default"
            size="sm"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </>
      );
    };

    const validateDescription = (note) => {
      // Check if there are changes
      if (fieldDescription === note) {
        setDescriptionError("No changes have been made");

        return false;
      }

      // Check min length
      if (note.length === 0) {
        setDescriptionError("Description value can't be empty");

        return false;
      }

      // Check max length
      if (note.length > 256) {
        setDescriptionError(
          `Description value is too long. Current length is ${note.length} characters. Maximum length is 256 characters`
        );

        return false;
      }

      // Check format
      if (!/^[A-Za-z0-9 ,()-:;.]*$/.test(note)) {
        setDescriptionError("Invalid characters in description value");

        return false;
      }

      setDescriptionError("");

      return true;
    };

    const validateForm = () => {
      return validateDescription(description);
    };

    const handleSave = async () => {
      if (!validateForm()) return false;

      setIsLoading(true);

      const updateResult = await updateField(
        { fieldKey },
        { description, updatedBy: { ...userInfo } }
      );

      if (updateResult.modifiedCount) {
        setIsLoading(false);
        setIsEditing(false);

        // Update field state
        const currentFieldCopy = { ...currentField };
        currentFieldCopy.description = description;
        currentFieldCopy.updatedAt = updateResult.updatedAt;
        currentFieldCopy.updatedBy = { ...userInfo };

        handleCurrentFieldUpdate(currentFieldCopy);
      }
    };

    const handleCancel = () => {
      setDescription(fieldDescription);
      setIsEditing(false);
    };

    const handleDescription = (note) => {
      if (validateDescription(note)) {
        setDescription(note);
      }
    };

    return (
      <TabPane tabId="1">
        <CardBody>
          <Card body style={{ marginBottom: "1rem" }}>
            <CardText>
              <b>Field set:</b> {phraseToProperCase(fieldset)}
            </CardText>
            <CardText>
              <b>Field key:</b> {currentField.fieldKey}
            </CardText>
            {!isEditing && (
              <CardText>
                <b>Description:</b> {currentField.description}
              </CardText>
            )}
            {isEditing && (
              <>
                <CardText>
                  <b>Description</b> <sup>*</sup>
                </CardText>
                <FormGroup className="mb-0rem">
                  <Input
                    invalid={descriptionError ? true : false}
                    type="textarea"
                    id="field-description"
                    rows="2"
                    placeholder="Max. length: 256 characters"
                    defaultValue={description}
                    disabled={isLoading}
                    onChange={(e) => handleDescription(e.target.value)}
                  />
                  {descriptionError && (
                    <FormFeedback>{descriptionError}</FormFeedback>
                  )}
                </FormGroup>
                <CardTitle>
                  <small>
                    <sup>*</sup> <i>Required</i>
                  </small>
                </CardTitle>
              </>
            )}
            {isStaged && (
              <CardText>
                <b>Status:</b> <Badge color="warning">Pending release</Badge>
              </CardText>
            )}
          </Card>
          <Card body inverse style={{ backgroundColor: "#f7f7f7" }}>
            {currentField.createdAt && (
              <CardText>
                <CreatedByField />
              </CardText>
            )}
            {currentField.updatedAt && (
              <CardText>
                <UpdatedByField />
              </CardText>
            )}
          </Card>
          {!isEditing && (
            <EditButton handleEdit={setIsEditing} isStaged={isStaged} />
          )}
          {isEditing && (
            <SaveCancelButtons
              handleSave={handleSave}
              handleCancel={handleCancel}
              isLoading={isLoading}
              isStaged={isStaged}
            />
          )}
        </CardBody>
      </TabPane>
    );
  };

  const OptionsTab = (props) => {
    const {
      fieldKey,
      isOhPackage,
      validationAcceptableValue,
      validationOptions,
      validationUseTrans,
      validationTrans,
      isStaged,
      staged,
      handleIsStaged,
      handleCurrentFieldUpdate
    } = props;

    const [options, setOptions] = useState(validationOptions);
    const [acceptableValue, setAcceptableValue] = useState(
      validationAcceptableValue
    );
    const [transformation, setTransformation] = useState(validationTrans);
    const [useTransformation, setUseTransformation] =
      useState(validationUseTrans);
    const [acceptableValueError, setAcceptableValueError] = useState("");
    const [optionsError, setOptionsError] = useState(null);
    const [releaseNote, setReleaseNote] = useState("");
    const [releaseNoteError, setReleaseNoteError] = useState("");
    const [formError, setFormError] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isDiscarding, setIsDiscarding] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const optionsValue = options.join(",");
    const isOptions = options.length > 0;

    const validateOptions = (options) => {
      const optionsStr = options.join(",");

      // Check min length
      if (optionsStr.length === 0) {
        setOptionsError("Options value can't be empty");

        return false;
      }

      // Check max length
      if (optionsStr.length > 1024) {
        setOptionsError(
          `Options value is too long. Current length is ${optionsStr.length} characters. Maximum length is 1024 characters`
        );

        return false;
      }

      // Check format
      if (!/^[A-Za-z0-9 \-,&/]*$/.test(optionsStr)) {
        setOptionsError("Invalid characters in options value");

        return false;
      }

      setOptionsError("");

      return true;
    };

    const validateAcceptableOption = (option) => {
      // Check if option exists in list
      if (!options.includes(option)) {
        setAcceptableValueError("Select an acceptable value from list");

        return false;
      }

      setAcceptableValueError("");

      return true;
    };

    const validateReleaseNote = (note) => {
      // Check min length
      if (note.length === 0) {
        setReleaseNoteError("Release note value can't be empty");

        return false;
      }

      // Check max length
      if (note.length > 256) {
        setReleaseNoteError(
          `Release note value is too long. Current length is ${note.length} characters. Maximum length is 256 characters`
        );

        return false;
      }

      // Check format
      if (!/^[A-Za-z0-9 ,]*$/.test(note)) {
        setReleaseNoteError("Invalid characters in release note value");

        return false;
      }

      setReleaseNoteError("");

      return true;
    };

    const hasFormChanged = () => {
      const isOptionsSame = validationOptions.join(",") === options.join(",");
      const isTransSame = validationTrans.join() === transformation.join();

      if (isOhPackage) {
        const isAcceptableOptionSame =
          acceptableValue === validationAcceptableValue;

        if (isOptionsSame && isAcceptableOptionSame) {
          setFormError("There are no changes to stage");

          return false;
        }
      } else {
        if (isOptionsSame && isTransSame) {
          setFormError("There are no changes to stage");

          return false;
        }
      }

      setFormError("");

      return true;
    };

    const validateForm = () => {
      if (!validateOptions(options)) return false;

      if (isOhPackage && !validateAcceptableOption(acceptableValue))
        return false;

      if (!validateReleaseNote(releaseNote)) return false;

      return hasFormChanged();
    };

    const handleDiscard = async () => {
      setIsLoading(true);

      // Update status of field change
      const logEntryDoc = {
        action: "discard",
        updatedBy: { ...userInfo }
      };

      const logEntryResult = await updateLogEntry(staged, logEntryDoc);

      if (logEntryResult.modifiedCount) {
        // Update staged flag
        const fieldUpdateDoc = {
          staged: null,
          updatedBy: { ...userInfo }
        };

        const stageResult = await updateField({ fieldKey }, fieldUpdateDoc);

        if (stageResult.modifiedCount) {
          setIsLoading(false);
          setIsDiscarding(false);

          // Update field state
          const currentFieldCopy = { ...currentField };
          currentFieldCopy.staged = null;
          currentFieldCopy.updatedAt = stageResult.updatedAt;
          currentFieldCopy.updatedBy = { ...userInfo };
          handleCurrentFieldUpdate(currentFieldCopy);

          setIsStaged(false);
        }
      }
    };

    const handleCancelDiscard = () => {
      setIsDiscarding(false);
    };

    const handleSave = async () => {
      if (!validateForm()) return false;

      setIsSaving(true);

      const entryId = uuidv4();

      // Insert staged note as a log entry
      const logEntryDoc = {
        entryId,
        scope: "fieldDefinitionChanges",
        action: "stage",
        released: false,
        object: {
          fieldSet: fieldset,
          fieldKey: `${fieldPart}_${sourcePart}`,
          releaseNote,
          update: {
            current: {
              options: validationOptions,
              useTransformation: validationUseTrans,
              transformation: validationTrans,
              acceptableValue: validationAcceptableValue,
              isOhPackage
            },
            next: {
              options: [...options],
              useTransformation,
              transformation,
              acceptableValue: acceptableValue
            }
          }
        },
        createdBy: { ...userInfo }
      };

      console.log(logEntryDoc);

      const logEntryResult = await createLogEntry(logEntryDoc);

      if (logEntryResult.insertedCount) {
        // Update staged flag
        const fieldUpdateDoc = {
          staged: entryId,
          updatedBy: { ...userInfo }
        };

        const stageResult = await updateField({ fieldKey }, fieldUpdateDoc);

        if (stageResult.modifiedCount) {
          setIsSaving(false);
          setIsEditing(false);
          handleIsStaged(true);

          // Update field state
          const currentFieldCopy = { ...currentField };
          currentFieldCopy.staged = entryId;
          currentFieldCopy.updatedAt = stageResult.updatedAt;
          currentFieldCopy.updatedBy = { ...userInfo };

          handleCurrentFieldUpdate(currentFieldCopy);
        }
      }
    };

    const handleCancel = () => {
      setOptions(validationOptions);
      setAcceptableValue(validationAcceptableValue);
      setReleaseNote("");

      setFormError("");
      setOptionsError("");
      setAcceptableValueError("");
      setReleaseNoteError("");

      setTransformation(validationTrans);
      setUseTransformation(validationUseTrans);

      setIsEditing(false);
    };

    const handleOptions = (value) => {
      const newOptions = value.split(",").map((o) => o.trim());

      setFormError("");

      if (validateOptions(newOptions)) {
        setOptions(newOptions);

        // Update acceptable option
        if (!newOptions.includes(acceptableValue)) {
          setAcceptableValue("");
        }
      }
    };

    const handleAcceptableOption = (value) => {
      setFormError("");

      if (validateAcceptableOption(value)) {
        setAcceptableValue(value);
      }
    };

    const handleReleaseNote = (note) => {
      setFormError("");

      if (validateReleaseNote(note)) {
        setReleaseNote(note);
      }
    };

    const EditButton = (props) => {
      const { handleEdit } = props;

      return (
        <Button
          outline
          color="success"
          size="sm"
          className="mt-1rem"
          onClick={() => handleEdit(true)}
        >
          Edit options
        </Button>
      );
    };

    const DiscardButton = (props) => {
      const { handleDiscard } = props;

      return (
        <Button
          color="danger"
          size="sm"
          className="mt-1rem"
          onClick={() => handleDiscard(true)}
        >
          Discard staged changes{" "}
          {isDiscarding && <Spinner size="sm" color="light" />}
        </Button>
      );
    };

    const DiscardCancelButtons = (props) => {
      const { handleDiscard, handleCancelDiscard, isLoading } = props;

      return (
        <>
          <hr />
          Are you sure you want to discard these changes?
          <br />
          <Button
            className="discard-cancel-buttons"
            color="success"
            size="sm"
            onClick={handleCancelDiscard}
            disabled={isLoading}
          >
            Not sure
          </Button>
          <Button
            className="discard-cancel-buttons"
            color="danger"
            size="sm"
            onClick={handleDiscard}
            disabled={isLoading}
          >
            Yes, I'm sure {isLoading && <Spinner size="sm" color="light" />}
          </Button>
        </>
      );
    };

    const SaveCancelButtons = (props) => {
      const { handleSave, handleCancel, isSaving } = props;

      const isError =
        optionsError || acceptableValueError || releaseNoteError || isSaving
          ? true
          : false;

      return (
        <>
          <Button
            className="mt-1rem"
            color="success"
            size="sm"
            onClick={handleSave}
            disabled={isError}
          >
            Stage changes {isSaving && <Spinner size="sm" color="light" />}
          </Button>
          <Button
            className="mt-1rem"
            color="default"
            size="sm"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Alert className="mt-2rem" color="warning" fade={false}>
            Staged changes must be later released by an administrator to take
            effect
          </Alert>
        </>
      );
    };

    const ShowStageLogEntry = () => {
      const [isShowing, setIsShowing] = useState(false);
      const [isLoading, setIsLoading] = useState(false);
      const [logEntry, setLogEntry] = useState(null);

      const handlePullEntryId = async () => {
        setIsLoading(true);

        // Pull log entry
        const logEntryResults = await getLogEntry(staged);

        if (logEntryResults) {
          setLogEntry({ ...logEntryResults });

          setIsShowing(true);
          setIsLoading(false);
        }
      };

      return !isShowing ? (
        <FormGroup className="mb-0rem mt-1rem">
          <Button color="warning" size="sm" onClick={handlePullEntryId}>
            Pull staged changes{" "}
            {isLoading && <Spinner size="sm" color="light" />}
          </Button>
        </FormGroup>
      ) : (
        <Card body inverse style={{ backgroundColor: "#f7f7f7" }}>
          <CardText>
            <b>Staged Field Options: </b>
            <br />
            {logEntry.object.update.next.options.join(",")}
          </CardText>
          <CardText>
            <b>Staged Value Transformation: </b>
            <br />
            {`[${logEntry.object.update.next.transformation.join("], [")}]`}
          </CardText>
          {logEntry.object.update.current.isOhPackage && (
            <CardText>
              <b>Staged Acceptable Value: </b>
              <br />
              {logEntry.object.update.next.acceptableValue}
            </CardText>
          )}
          <CardText>
            <b>Release Note: </b>
            <br />
            {logEntry.object.releaseNote}
          </CardText>
          <CardText>
            <b>Staged by:</b>
            <br /> {logEntry.createdBy.name} {" @ "}
            <Moment>{logEntry.createdAt}</Moment>
          </CardText>
        </Card>
      );
    };

    const handleUseTransformation = (flag) => {
      if (!flag) {
        setTransformation([]);
      }

      setUseTransformation(flag);
    };

    return (
      <TabPane tabId="2">
        <CardBody>
          <Form>
            <Card body>
              {!isEditing && (
                <>
                  <CardText>
                    <b>Field Options</b>
                  </CardText>
                  <CardText>{optionsValue}</CardText>
                  <CardText>
                    <b>Values Transformation</b>
                  </CardText>
                  <CardText>
                    {useTransformation
                      ? `[${transformation.join("], [")}]`
                      : "<empty list>"}
                  </CardText>
                  {isOhPackage && acceptableValue && (
                    <>
                      <CardText>
                        <b>Acceptable value (OH Package)</b>
                      </CardText>
                      <CardText>{acceptableValue}</CardText>
                    </>
                  )}
                  {isStaged && !isDiscarding && <ShowStageLogEntry />}
                </>
              )}
              {isEditing && (
                <>
                  <CardText>
                    <b>Field Options</b> <sup>* &dagger;</sup>
                  </CardText>
                  <FormGroup className="mb-0rem">
                    <Input
                      invalid={optionsError ? true : false}
                      type="textarea"
                      id="field-options"
                      rows="2"
                      placeholder="Max. length: 256 characters"
                      defaultValue={optionsValue}
                      disabled={isSaving}
                      onChange={(e) => handleOptions(e.target.value)}
                    />
                    {optionsError && (
                      <FormFeedback>{optionsError}</FormFeedback>
                    )}
                  </FormGroup>
                  <CardTitle>
                    <small>
                      <sup>*</sup> <i>Required</i>
                      <br />
                      <sup>&dagger;</sup>{" "}
                      <i>Options should be separated by a comma</i>
                    </small>
                  </CardTitle>
                  {isOhPackage && (
                    <>
                      <CardText>
                        <b>Acceptable value (OH Package)</b> <sup>*</sup>
                      </CardText>
                      <FormGroup className="mb-1rem">
                        <Input
                          invalid={acceptableValueError ? true : false}
                          type="select"
                          id="acceptable-value"
                          value={acceptableValue}
                          disabled={isSaving}
                          onChange={(e) =>
                            handleAcceptableOption(e.target.value)
                          }
                        >
                          <option key={0} value={""}>
                            Select acceptable value
                          </option>
                          {optionsValue.split(",").map((o, i) => (
                            <option key={i + 1} value={o}>
                              {o}
                            </option>
                          ))}
                        </Input>
                        {acceptableValueError && (
                          <FormFeedback>{acceptableValueError}</FormFeedback>
                        )}
                      </FormGroup>
                    </>
                  )}
                  {isOptions && (
                    <FormGroup check className="mb-1rem">
                      <Input
                        type="checkbox"
                        id="use-transformation"
                        defaultChecked={useTransformation}
                        onChange={(e) =>
                          handleUseTransformation(e.target.checked)
                        }
                      />{" "}
                      <Label check>Use transformation</Label>
                    </FormGroup>
                  )}
                  {useTransformation && (
                    <div className="mb-1rem">
                      <AssocTable
                        options={options}
                        transformation={transformation}
                        updateTransformation={setTransformation}
                      />
                    </div>
                  )}
                  <CardText>
                    <b>Release Note</b> <sup>*</sup>
                  </CardText>
                  <FormGroup className="mb-0rem">
                    <Input
                      invalid={releaseNoteError ? true : false}
                      type="textarea"
                      id="field-release-note"
                      rows="2"
                      placeholder="Max. length: 256 characters"
                      defaultValue={releaseNote}
                      disabled={isSaving}
                      onChange={(e) => handleReleaseNote(e.target.value)}
                    />
                    {releaseNoteError && (
                      <FormFeedback>{releaseNoteError}</FormFeedback>
                    )}
                  </FormGroup>
                  <CardTitle>
                    <small>
                      <sup>*</sup> <i>Required</i>
                    </small>
                  </CardTitle>
                </>
              )}
            </Card>
            {isEditing && formError && (
              <Alert className="mt-1rem" color="primary">
                {formError}
              </Alert>
            )}
            {!isEditing && !isStaged && (
              <EditButton handleEdit={setIsEditing} />
            )}
            {isStaged && !isDiscarding && (
              <DiscardButton handleDiscard={setIsDiscarding} />
            )}
            {isDiscarding && (
              <DiscardCancelButtons
                handleDiscard={handleDiscard}
                handleCancelDiscard={handleCancelDiscard}
                isLoading={isLoading}
              />
            )}
            {isEditing && (
              <SaveCancelButtons
                handleSave={handleSave}
                handleCancel={handleCancel}
                isSaving={isSaving}
              />
            )}
          </Form>
        </CardBody>
      </TabPane>
    );
  };

  const cardHeaderClass = isStaged
    ? "card-header-warning"
    : "card-header-success";

  return (
    <div className="DataField">
      <Card className="FieldCard">
        <CardHeader className={cardHeaderClass}>
          {phraseToProperCase(fieldPart)} ({sourcePart})
        </CardHeader>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggleTabs("1");
              }}
              style={{ marginLeft: "0.5rem" }}
            >
              Data Field
            </NavLink>
          </NavItem>
          {isOptions && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  toggleTabs("2");
                }}
              >
                Options
              </NavLink>
            </NavItem>
          )}
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => {
                toggleTabs("3");
              }}
            >
              Mapping
            </NavLink>
          </NavItem>
          {isReinforcementAudit && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "4" })}
                onClick={() => {
                  toggleTabs("4");
                }}
              >
                Pole Reinforcement QA
              </NavLink>
            </NavItem>
          )}
        </Nav>
        <TabContent activeTab={activeTab}>
          <DataFieldTab
            isStaged={isStaged}
            fieldKey={currentField.fieldKey}
            fieldDescription={currentField.description}
            handleCurrentFieldUpdate={setCurrentField}
          />
          {isOptions && (
            <OptionsTab
              fieldKey={currentField.fieldKey}
              isOhPackage={isOhPackage}
              validationOptions={currentField.validation.options}
              validationUseTrans={currentField.validation.useTransformation}
              validationTrans={currentField.validation.transformation}
              validationAcceptableValue={
                currentField.validation.acceptableValue
              }
              isStaged={isStaged}
              staged={currentField.staged}
              handleIsStaged={setIsStaged}
              handleCurrentFieldUpdate={setCurrentField}
            />
          )}
          <MappingTab
            isStaged={isStaged}
            fieldKey={currentField.fieldKey}
            aliases={currentField.alias}
            handleCurrentFieldUpdate={setCurrentField}
          />
          {isReinforcementAudit && (
            <PoleReinforcementQaTab
              isStaged={isStaged}
              fieldKey={currentField.fieldKey}
              qa={currentField.qa ? currentField.qa : {}}
              handleCurrentFieldUpdate={setCurrentField}
            />
          )}
        </TabContent>
      </Card>
    </div>
  );
};

export default DataField;
