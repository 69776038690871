import {bulkGet} from "./bulkServices";
import axios from "axios";
import {config} from "./config";
import {FileTypeAbbr} from "../utils/appUtils";

const stage = config.stage;
const settings = config[stage].appService;

const getPitAndPtmUploads = async (filterNonExtracted) => {
    const query = {
      collection: "Uploads",
      filter: {
        category: "PTM",
        "createdBy.org" : {$in: ['Metro Engineering Solutions', 'OCG']},
        type: { $in: ['poleTopMaintenance', 'poleTopMaintenance3', 'poleInspection']},
        "support.photos.list" : { $exists: true, $ne: [] }
      },
      sort: { "createdAt": -1 },
      project: {
        _id: 0,
        "circuit": 1,
        "fileName": 1,
        "type": 1
      },
      confirm: true
    };

    if (filterNonExtracted) {
      query.filter["support.photos.extracted"] = { "$exists": false }
      query.filter["support.photos.complete"] = true;
    }

  return await bulkGet(query);
  };


const downloadCsvData = async (fileName, contentType) => {
  const url = `${settings.baseUrl}/api/downloadUploads?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "GET",
    url: url,
    params: { fileName, contentType }
  });

  return data;
};


const downloadBase64MediaData = async (fileName, contentType) => {
  const url = `${settings.baseUrl}/api/downloadBase64Media?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "GET",
    url: url,
    params: { fileName, contentType }
  });

  return data;
};

const getPhotoLists = async (circuit) => {
  const query = {
    collection: "Uploads",
    filter: {
      circuit,
      category: "PTM",
      "createdBy.org" : {$in: ['Metro Engineering Solutions', 'OCG']},
      type: { $in: ['poleTopMaintenance', 'poleTopMaintenance3', 'poleInspection'] }
    },
    sort: {},
    project: {
      _id: 0,
      "support.photos.list": 1,
      "support.photos.extracted": 1,
      "support.photos.complete": 1,
      "support.compressed.final.fileName": 1,
      "type": 1,
      "fileName": 1
    },
    confirm: true
  };

  const res = await bulkGet(query);

  if (res.response?.length && res.response[0].support?.photos?.list?.length) {
    return res.response.reduce((result, value) => {
      if (value.support?.photos?.list?.length) {
        result.photoList.push.apply(result.photoList, value.support.photos.list);
      }

      const fileInfo = {
        extracted: value.support?.photos?.extracted,
        complete: value.support?.photos?.complete,
        zipName: value.support?.compressed?.final?.fileName,
        csvName: value.fileName,
        type: value.type ? FileTypeAbbr[value.type] : undefined
      }

      result.fileInfos.push(fileInfo);

      return result;
    }, {photoList: [], fileInfos: []});
  }
  return undefined;
};

const copyForExtraction = async (circuit, fileType, csvName, zipName) => {
  const url = `${settings.baseUrl}/api/copyForExtraction?code=${settings.hostKey}`;

  const { data } = await axios({
    method: "POST",
    url: url,
    params: { fileName: zipName, circuit, fileType, csvName }
  });

  return data;
}

const getPictureBytesBase64 = async (circuit, type, fileName) => {
  const filePath = 'output/' + circuit + '/' + type + '/' + fileName;
  const fileType = 'image/jpeg';
  return await downloadBase64MediaData(filePath, fileType);
}


export {
  getPitAndPtmUploads,
  downloadCsvData,
  downloadBase64MediaData,
  getPictureBytesBase64,
  getPhotoLists,
  copyForExtraction
};
