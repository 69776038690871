const FileTypeAbbr = {
    poleInspection: "PIT",
    poleInspectionAudit: "PIA",
    poleReinforcement: "RIP",
    poleReinforcementAudit: "RIA",
    poletopInspection: "PTP",
    poleTopMaintenance: "PTM",
    poleTopMaintenance3: "PTM3"
};

const PhotoUploadAbbr = {
    poleInspection: "PIT",
    poleInspectionAudit: "PIA",
    poleReinforcement: "RIP",
    poleReinforcementAudit: "RIA",
    poletopInspection: "PTP",
    poleTopMaintenance: "PTM",
    poleTopMaintenance3: "PTM"
}

const ReversedFileTypeAbbr = Object.fromEntries(
    Object.entries(FileTypeAbbr).map(([key, value]) => [value, key])
);

export {
    FileTypeAbbr,
    ReversedFileTypeAbbr,
    PhotoUploadAbbr
}
