import React, { useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  Nav,
  NavItem,
  NavLink,
  Progress,
  TabContent,
  TabPane,
  Spinner,
  Table
} from "reactstrap";
import {
  getDownloadUrl,
  downloadData,
  updateUpload,
  getUploads
} from "../services/filesService";
import {
  getDataFileEntries,
  createDataFileEntry,
  deleteDataFileEntry
} from "../services/dataFileService";
import {
  getAllReinfData,
  getDataByFileName,
  getDuplicatePoles,
  checkPoleInspectionDataTransfer
} from "../services/uploadedFileCustomServices";
import { getFields } from "../services/fieldsService";
import { aggregateData } from "../libs/ohPackage";
import { genCircuitAuditPipeline } from "../models/CircuitAuditPipeline";
import { createCircuitAudit } from "../services/circuitAuditService";
import csv from "csvtojson/v2";
import _ from "lodash";
import Moment from "react-moment";
import "moment-timezone";
import classnames from "classnames";
import prettyBytes from "pretty-bytes";
import { phraseToProperCase } from "../libs/case-utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import "./UploadedFile.css";
import {FileTypeAbbr, ReversedFileTypeAbbr} from "../utils/appUtils";

// Set the timezone for every instance.
Moment.globalTimezone = "America/Detroit";

// Set the output format for every react-moment instance.
Moment.globalFormat = "MM/DD/YYYY HH:mm:ss";

const ohPackageSuffix = "PIT";
const ohPackagePtpSuffix = "PTP";
const ohPackagePtpName = "poletopInspection";
const ohPackageRipName = "poleReinforcement";

const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const CUR_VERSION = "1.1.0";

const UploadedFile = (props) => {
  const {
    uploadId,
    fileName,
    circuit,
    type,
    contentType,
    category,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
    errors,
    datapoints: defDatapoints,
    support,
    user,
    status: defStatus,
    events,
    version
  } = props.upload;

  const userInfo = {
    id: user.id,
    name: user.name,
    org: user.org,
    email: user.email
  };

  const [status, setStatus] = useState(defStatus);
  const [datapoints, setDatapoints] = useState(
    defDatapoints ? defDatapoints : 0
  );
  const [fileBaseName, fileExtension] = fileName.split(".");
  const [primaryCircuit, sourceSuffix, vendorName] = fileBaseName.split("_");
  const ohPackFileName = `${fileBaseName}-OHPackage.${fileExtension}`;
  const isProcessed = status === "processed";
  const isDuplicate = status === "duplicate";
  const isEvents = events ? (events.length ? true : false) : false;

  const isOhPackage = ohPackageSuffix === sourceSuffix && isProcessed;

  const [isLoading, setIsLoading] = useState(false);
  const [isTransferring, setIsTransferring] = useState(false);
  const [isTransferFinished, setIsTransferFinished] = useState(false);
  const [dataTransferResults, setDataTransferResults] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletionFinished, setIsDeletionFinished] = useState(false);
  const [dataDeletionResults, setDataDeletionResults] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploadDbEvents, setUploadDbEvents] = useState(isEvents ? events : []);
  const [isDownload, setIsDownload] = useState(false);
  const [hrefCsv, setHrefCsv] = useState(null);
  const [ohPackSize, setOhPackSize] = useState(null);
  const [ohSources, setOhSources] = useState(null);
  const [numOfDefects, setNumOfDefects] = useState(0);
  const [docsToInsert, setDocsToInsert] = useState([]);
  const [docsToDelete, setDocsToDelete] = useState([]);
  const [docsInFile, setDocsInFile] = useState([]);
  const [foundDuplicatesPoles, setFoundDuplicatesPoles] = useState(null);
  const [waitForPit, setWaitForPit] = useState(false);

  // Restrict access to features
  const showErrors = user.role !== "vendor" ? true : false;
  const showDownloadOption = user.role !== "vendor" ? true : false;
  const showToDev = user.role === "dev" ? true : false;
  const showDataProcessing = user.role !== "vendor" ? true : false;
  const showOHPackage = user.role !== "vendor" ? true : false;
  const isCompressed = support?.compressed !== undefined;
  const isPhotoFinal = isCompressed
    ? support.compressed?.final
      ? true
      : false
    : false;
  const isPhotos = support?.photos ? true : false;

  // Control tabs
  const [activeTab, setActiveTab] = useState("1");
  const toggleTabs = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const cardHeaderClass =
    errors && showErrors ? "card-header-danger" : "card-header-success";

  const RulesStatus = () => {
    const statusClass = errors ? "danger" : "success";
    const statusCaption = errors ? "Failed" : "Passed";

    return <Badge color={statusClass}>{statusCaption}</Badge>;
  };

  const getUrl = async (e, key) => {
    e.preventDefault();

    try {
      const download = await getDownloadUrl(key, "uploads");
      window.open(download);
    } catch (e) {}
  };

  const getAcceptableValues = (fields) => {
    let ret = {};

    fields.map((field) => {
      if (field.validation.acceptableValue) {
        const fieldName = field.fieldKey.split("_")[0];

        ret[fieldName] = field.validation.acceptableValue;
      }

      return null;
    });

    return ret;
  };

  const getRipFileName = async () => {
    const query = {
      page: 1,
      count: 1,
      circuit: primaryCircuit,
      type: ohPackageRipName
    };

    const upload = await getUploads(query);

    if (upload.length > 0) {
      return upload[0].fileName;
    } else {
      return null;
    }
  };

  const handleBuildDownload = async (e, source) => {
    e.preventDefault();

    setIsLoading(true);

    const sources = ["PIT"];

    try {
      // Get PIT data
      const sourceEntries = await getDataFileEntries({
        source,
        page: 1,
        count: 20000
      });

      // Get PTP data
      const ptpEntries = await getDataFileEntries({
        source: `${primaryCircuit}_${ohPackagePtpSuffix}_${vendorName}.${fileExtension}`,
        page: 1,
        count: 20000
      });

      // Get PTP data
      const ptpFields = await getFields({
        type: ohPackagePtpName,
        editable: true,
        page: 1,
        count: 1000
      });

      // Add additional sources
      if (ptpEntries.length) {
        sources.push("PTP");
      }

      // Get PTP acceptable values
      const ptpAcceptableValues = getAcceptableValues(ptpFields);

      // Check for RIP file
      const ripFileName = await getRipFileName();

      // Get RIP data
      let ripEntries = [];

      if (ripFileName) {
        ripEntries = await getDataFileEntries({
          source: ripFileName,
          page: 1,
          count: 20000
        });
      }

      // Add additional sources
      if (ripEntries.length) {
        sources.push("RIP");
      }

      // Merge CSV arrays
      const csvArray = aggregateData(
        sourceEntries,
        ptpEntries,
        ptpAcceptableValues,
        ripEntries,
        sources
      );

      // Create URL for CSV data
      const fileCsv = new Blob([csvArray.join("\n")], {
        type: "text/csv"
      });

      setHrefCsv(URL.createObjectURL(fileCsv));
      setOhPackSize(fileCsv.size);
      setOhSources(sources);
      setNumOfDefects(csvArray.length - 1);

      setIsLoading(false);
      setIsDownload(true);
    } catch (e) {}
  };

  const ShowDownloadLink = () => {
    return numOfDefects ? (
      <>
        <CardText>
          <b>Status:</b> Defects found
        </CardText>
        <CardText>
          <b>Sources:</b> {ohSources.join(", ")}
        </CardText>
        <a href={hrefCsv} download={`${ohPackFileName}`}>
          <FontAwesomeIcon icon={faDownload} /> {ohPackFileName} (
          {prettyBytes(ohPackSize)})
        </a>
      </>
    ) : (
      <>
        <CardText>
          <b>Status:</b> No defects found
        </CardText>
        <CardText>
          <b>Sources:</b> {ohSources.join(", ")}
        </CardText>
      </>
    );
  };

  const CreatedByField = (props) => {
    const { by, at } = props;

    return (
      <>
        <b>Created by:</b>
        <br /> {by} {" @ "}
        <Moment>{at}</Moment>
      </>
    );
  };

  const UpdatedByField = (props) => {
    const { by, at } = props;

    return (
      <>
        <b>Updated by:</b>
        <br /> {by} {" @ "}
        <Moment>{at}</Moment>
      </>
    );
  };

  const getDocsFromArray = (arr, source) => {
    // Set timestamp
    const timestamp = new Date().getTime();

    const getMap = (GLNX, GLNY) => {
      const glnx = parseInt(GLNX);
      const glny = parseInt(GLNY);
      let x = null;
      const y = 2 * parseInt(parseInt(glny / 1000) / 2);

      if (glnx <= 968000) {
        x = 3 * parseInt((parseInt(glnx / 1000) + 2000) / 3) - 2000;
      } else {
        x = 3 * parseInt((parseInt(glnx / 1000) + 1000) / 3) - 1000;
      }

      return `${x}-${y}`;
    };

    // Exception: transform to YYYY-M-D (Excel default)
    const transformDate = (dateStr) => {
      const [m, d, y] = dateStr.split("/");

      const pad = (n) => {
        return n.length === 1 ? `0${n}` : n;
      };

      if (m === undefined || d === undefined || y === undefined) return dateStr;

      return `${y}-${pad(m)}-${pad(d)}`;
    };

    const padDecimals = (coord, padding) => {
      let [intPart, decPart] = `${coord}`.split(".");

      if (decPart !== undefined) {
        decPart = decPart.padEnd(padding, "0");
      } else {
        // Correct if no decimals
        decPart = "000000";
      }

      return `${intPart}.${decPart}`;
    };

    const transformDoc = (doc, docKeys) => {
      let newDoc = {};
      const hold = {
        TrussInstalledSize: null
      };

      docKeys.map((key) => {
        const keyParts = key.split("_");
        const field = keyParts[0];
        let value = null;

        // Transformation tasks by field
        switch (field) {
          case "InspectionDate":
            value = transformDate(doc[key]);
            break;
          case "InspectionQADate":
            value = transformDate(doc[key]);
            break;
          // Legacy field
          case "InspectionVerificationDate":
            value = transformDate(doc[key]);
            break;
          case "ReinforcementDate":
            value = transformDate(doc[key]);
            break;
          case "GPSLongitude":
            value = padDecimals(doc[key], 6);
            break;
          case "GPSLaitude":
            value = padDecimals(doc[key], 6);
            break;
          case "TrussInstalledSize":
            hold["TrussInstalledSize"] = doc[key];

            value = doc[key];
            break;
          default:
            value = doc[key];
        }

        // Exceptions after field renaming
        let exceptField = field;
        switch (field) {
          case "ReinforcementYear":
            exceptField = "ReinforcementDate";
            break;
          case "InspectionVerificationDate":
            exceptField = "InspectionQADate";
            break;
          case "ConditionOfPole":
            exceptField = "PoleStatus";
            break;
          default:
          // Do nothing
        }

        newDoc[exceptField] = value;

        return null;
      });

      // Exceptions (overrides)
      if (hold["TrussInstalledSize"] === "Truss Not Installed") {
        newDoc["ReinforcementDate"] = "";
      }

      return newDoc;
    };

    // Add metadata to records
    const docs = arr.map((doc) => {
      const docKeys = Object.keys(doc);
      const fieldParts = docKeys[0].split("_");
      const suffix = fieldParts[1];
      const glnxy = doc[`GLNX-GLNY_${suffix}`];
      const [GLNX, GLNY] = glnxy ? glnxy.split("-") : [null, null];
      const map = getMap(GLNX, GLNY);
      const circuit = doc[`OHPrimaryCircuitNumber_${suffix}`];
      const status = "received";

      const newDoc = {
        dataPointId: `${GLNX}-${GLNY}`,
        status,
        source,
        fieldset: ReversedFileTypeAbbr[suffix],
        suffix,
        contentType: contentType,
        map,
        circuit,
        GLNX: parseInt(GLNX),
        GLNY: parseInt(GLNY),
        document: transformDoc(doc, docKeys),
        parsedBy: userInfo,
        parsedAt: timestamp,
        version: CUR_VERSION
      };

      if (suffix === "PIT") {
        newDoc["inEsri"] = null;
      }

      return newDoc;
    });

    return docs;
  };

  const getDocIdsToInsert = (fromFile, fromDb) => {
    // Get datapoint ids from file
    const idsFile = fromFile.map((doc) => doc.dataPointId);

    // Get datapoint ids from db
    const idsDb = fromDb.map((doc) => doc.dataPointId);

    // Get datapoints not in db
    return _.difference(idsFile, idsDb);
  };

  const getDocIdsToDelete = (fromDb) => {
    return fromDb.map((doc) => {
      return {
        dataPointId: doc.dataPointId,
        source: doc.source
      };
    });
  };

  const calculateTransferProgress = async () => {
    setIsLoading(true);
    setWaitForPit(false);

    // Check PIT data transfer status
    if (type !== "poleInspection") {
      const pitStatusRes = await checkPoleInspectionDataTransfer(circuit);

      if (pitStatusRes.status === 200) {
        const pitStatus = pitStatusRes.response[0];

        if (!pitStatus.transferred) {
          setWaitForPit(true);
          setIsLoading(false);

          return false;
        }
      } else {
        return false;
      }
    }

    const prefix = FileTypeAbbr[type];

    // Get csv file from blob storage
    const csvData = await downloadData(fileName, "application/csv");

    // Convert csv to json array
    const jsonArray = await csv({
      noheader: false,
      output: "json"
    }).fromString(csvData);

    // Produce docs from json array
    const docsFile = getDocsFromArray(jsonArray, fileName);

    // Get data points from database
    const docsDb = await getDataFileEntries({
      source: fileName,
      page: 1,
      count: 20000
    });

    // Compare docs from both sources
    const docsInsert = getDocIdsToInsert(docsFile, docsDb);

    // Check if PIT data is in DB for non-PIT data
    // One-to-one globalId check: PTP against PIT
    // One-to-many count check: PIA.count >= PIT.count

    // Duplicate check: PIT, RIP, PIA (because they all have an inspection date)
    if (prefix !== "PTP") {
      const allInspDates = jsonArray.map((row) => {
        const inspectionDate = row[`InspectionDate_${prefix}`];

        return new Date(inspectionDate).getTime();
      });

      // Get earliest inspection year
      const earliestInspectionYear = new Date(
        Math.min(...allInspDates)
      ).getFullYear();

      // Check for pole in other circuits for same fileset
      const dupsRes = await getDuplicatePoles(
        `${circuit}`,
        type,
        docsInsert,
        earliestInspectionYear
      );

      if (dupsRes.status === 200) {
        if (dupsRes.response.length > 0) {
          setFoundDuplicatesPoles(dupsRes.response);
          setIsLoading(false);
        } else {
          // Success
          setDocsToInsert(docsInsert);
          setDocsInFile(docsFile);
          setIsLoading(false);
        }
      } else {
        // Error while checking for duplicates
        console.log(dupsRes);
      }
    } else {
      // Success
      setDocsToInsert(docsInsert);
      setDocsInFile(docsFile);
      setIsLoading(false);
    }
  };

  const calculateDeletionProgress = async () => {
    setIsLoading(true);

    let docsDb = [];
    let res = null;

    // Get data points from database
    if (type === "poleReinforcement") {
      res = await getAllReinfData(circuit);
    } else {
      res = await getDataByFileName(fileName);
    }

    if (res.status === 200) {
      if (res.response.length > 0) docsDb = res.response;
    }

    // Get dataPointIds to delete
    const docsDelete = getDocIdsToDelete(docsDb);

    setDocsToDelete(docsDelete);
    setIsLoading(false);
  };

  const ProcessDataTransfer = () => {
    return (
      <>
        <CardText>
          Click on the button below to calculate how many records should be
          transferred.
        </CardText>
        <Button
          outline
          color="secondary"
          size="sm"
          disabled={isLoading}
          onClick={() => calculateTransferProgress()}
        >
          Calculate progress {isLoading && <Spinner size="sm" color="dark" />}
        </Button>
      </>
    );
  };

  const ReportDuplicatePoles = () => {
    return (
      <Card className="mt-1rem">
        <CardHeader>Pole duplicates found</CardHeader>
        <CardBody className="overflow-500">
          <Alert color="danger">
            <b>Action Required!</b>
            <br />
            Data transfer can't continue until pole duplicates are resolved.
          </Alert>
          <Table size="sm" responsive bordered>
            <thead>
              <tr>
                <th>
                  <b>Pole</b>
                </th>
                <th>
                  <b>Inspection date</b>
                </th>
                <th>
                  <b>Circuit</b>
                </th>
                <th>
                  <b>Source</b>
                </th>
              </tr>
            </thead>
            <tbody>
              {foundDuplicatesPoles.map((pole, i) => {
                const inspectionDate = pole?.document?.InspectionDate && null;

                return (
                  <tr key={i}>
                    <td>{pole.dataPointId}</td>
                    <td>{inspectionDate ? inspectionDate : "NA"}</td>
                    <td>{pole.circuit}</td>
                    <td>{pole.source}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    );
  };

  const ProcessDataDeletion = () => {
    return (
      <>
        <CardText>
          Click on the button below to calculate how many records should be
          deleted.
        </CardText>
        <Button
          outline
          color="secondary"
          size="sm"
          disabled={isLoading}
          onClick={() => calculateDeletionProgress()}
        >
          Calculate progress {isLoading && <Spinner size="sm" color="dark" />}
        </Button>
      </>
    );
  };

  const updateFileStatus = async (
    newStatus,
    datapoints = null,
    transferred = null
  ) => {
    let doc = {
      status: newStatus,
      updatedBy: userInfo
    };

    // Update datapoint count
    if (datapoints !== null) {
      doc.datapoints = datapoints;
    }

    // Update transfer status
    if (transferred !== null) {
      doc.transferred = transferred;
    }

    // Update upload status
    await updateUpload(fileName, doc);

    // Update UI
    const update = !["processed", "submitted"].includes(newStatus);

    if (update) setStatus(newStatus);
  };

  const updateFileEvents = async (events) => {
    // Update upload db events (deletes)
    await updateUpload(fileName, { events, updatedBy: userInfo });
  };

  const initiateDataTransfer = async () => {
    setIsTransferring(true);

    const totalDocsInFile = docsInFile.length;
    const totalDocsToInsert = docsToInsert.length;
    let successfulInserts = 0;

    // Mark the beginning of process
    if (totalDocsToInsert) {
      await updateFileStatus("processing");
    }

    for (let i = 0; i < totalDocsInFile; i++) {
      const doc = docsInFile[i];
      const dataPointId = doc.dataPointId;

      if (docsToInsert.includes(dataPointId)) {
        try {
          const res = await createDataFileEntry(doc);

          if (res.status === 200) {
            successfulInserts += 1;

            setProgress(successfulInserts);
          }
        } catch (err) {
          // Wait for 1 second
          await delay(1000);
        }

        // Wait 100 miliseconds
        await delay(100);
      }
    }

    // Mark the end of successful process
    if (totalDocsToInsert === successfulInserts)
      await updateFileStatus("processed", successfulInserts, true);

    setDataTransferResults({
      totalDocsToInsert,
      totalDocsInFile,
      successfulInserts
    });
    setIsTransferring(false);
    setIsTransferFinished(true);
  };

  const retryTransfer = () => {
    setIsTransferFinished(false);
    setDocsToInsert([]);
    setDocsInFile([]);
  };

  const continueWithAnalysis = () => {
    toggleTabs("1");
    setStatus("processed");
    setDatapoints(docsToInsert);
    setDocsToInsert([]);
    setDocsInFile([]);
  };

  const initiateDataDeletion = async () => {
    setIsDeleting(true);

    const totalDocsToDelete = docsToDelete.length;
    let successfulDeletes = 0;

    // Copy registered events in session
    const dbEvents = [...uploadDbEvents];
    let timestamp = new Date().getTime();

    // Register db event
    dbEvents.push({
      event: "deletion attempt",
      docs: totalDocsToDelete,
      timestamp
    });

    // Add event to pile
    setUploadDbEvents(dbEvents);

    // Update events in db
    await updateFileEvents(dbEvents);

    for (let i = 0; i < totalDocsToDelete; i++) {
      const dataPoint = docsToDelete[i];

      try {
        const res = await deleteDataFileEntry(
          dataPoint.dataPointId,
          dataPoint.source
        );

        const result = res.deletedCount
          ? res.deletedCount === 1
            ? true
            : false
          : false;

        if (result) {
          successfulDeletes += 1;

          setProgress(successfulDeletes);
        }
      } catch (err) {
        // Wait for 1 second
        await delay(1000);
      }

      // Wait 100 miliseconds
      await delay(100);
    }

    // Mark the end of successful process
    if (totalDocsToDelete === successfulDeletes)
      await updateFileStatus("submitted", 0, false);

    // Copy registered events in session
    timestamp = new Date().getTime();

    // Register db event
    dbEvents.push({
      event: "successful deletion",
      docs: successfulDeletes,
      timestamp
    });

    // Add event to pile
    setUploadDbEvents(dbEvents);

    // Update events in db
    await updateFileEvents(dbEvents);

    setDataDeletionResults({
      totalDocsToDelete,
      successfulDeletes
    });
    setIsDeleting(false);
    setIsDeletionFinished(true);
  };

  const retryDeletion = () => {
    setIsDeletionFinished(false);
    setDocsToDelete([]);
  };

  const continueWithDataTransfer = () => {
    setStatus("submitted");
    setDocsToDelete([]);
    setDatapoints(0);
  };

  const ShowDataTransferResults = () => {
    const { totalDocsToInsert, successfulInserts } = dataTransferResults;
    const success = totalDocsToInsert === successfulInserts;

    return (
      <>
        {success && (
          <>
            <CardText>Transfer was successfully finished</CardText>
            <Button
              outline
              color="success"
              size="sm"
              onClick={() => continueWithAnalysis()}
            >
              Continue with analysis
            </Button>
          </>
        )}
        {!success && (
          <>
            <CardText>
              Transfer finished with errors. Click on the button below to retry
              transfer.
            </CardText>
            <Button
              outline
              color="danger"
              size="sm"
              onClick={() => retryTransfer()}
            >
              Retry transfer
            </Button>
          </>
        )}
      </>
    );
  };

  const ShowDataDeletionResults = () => {
    const { totalDocsToDelete, successfulDeletes } = dataDeletionResults;
    const success = totalDocsToDelete === successfulDeletes;

    return (
      <>
        {success && (
          <>
            <CardText>Deletion was successfully finished</CardText>
            <Button
              outline
              color="success"
              size="sm"
              onClick={() => continueWithDataTransfer()}
            >
              Continue with transfer
            </Button>
          </>
        )}
        {!success && (
          <>
            <CardText>
              Deletion finished with errors. Click on the button below to retry
              deletion.
            </CardText>
            <Button
              outline
              color="danger"
              size="sm"
              onClick={() => retryDeletion()}
            >
              Retry deletion
            </Button>
          </>
        )}
      </>
    );
  };

  const ReportDataTransferProgress = () => {
    const docsLeftToInsert = docsToInsert.length;
    const totalDocsInFile = docsInFile.length;
    const isLeft = docsLeftToInsert !== totalDocsInFile;

    const isPlural = docsLeftToInsert > 1;
    const message = `${
      isPlural ? "There are " : "There is "
    } ${docsLeftToInsert} record${isPlural ? "s" : ""} ${
      isLeft ? "left " : ""
    }to transfer to the database.`;

    return (
      <>
        {!isTransferring && !isTransferFinished && (
          <>
            <CardText>{message}</CardText>
            <Button
              outline
              color="danger"
              size="sm"
              disabled={isTransferring}
              onClick={() => initiateDataTransfer()}
            >
              Initiate transfer
            </Button>
          </>
        )}
        {isTransferring && (
          <>
            <CardText>Transfer initiated...</CardText>
            <div>
              <div className="text-center">
                {progress} of {docsLeftToInsert} record{isPlural ? "s" : ""}
              </div>
              <Progress
                color="success"
                value={progress}
                max={docsLeftToInsert}
              />
            </div>
          </>
        )}
        {isTransferFinished && <ShowDataTransferResults />}
      </>
    );
  };

  const ReportDeletionProgress = () => {
    const docsLeftToDelete = docsToDelete.length;
    const isPlural = docsLeftToDelete > 1;
    const message = `${
      isPlural ? "There are " : "There is "
    } ${docsLeftToDelete} record${
      isPlural ? "s" : ""
    } left to delete from the database.`;

    return (
      <>
        {!isDeleting && !isDeletionFinished && (
          <>
            <CardText>{message}</CardText>
            <Button
              outline
              color="danger"
              size="sm"
              disabled={isDeleting}
              onClick={() => initiateDataDeletion()}
            >
              Initiate deletion
            </Button>
          </>
        )}
        {isDeleting && (
          <>
            <CardText>Deletion initiated...</CardText>
            <div>
              <div className="text-center">
                {progress} of {docsLeftToDelete} record{isPlural ? "s" : ""}
              </div>
              <Progress
                color="danger"
                value={progress}
                max={docsLeftToDelete}
              />
            </div>
          </>
        )}
        {isDeletionFinished && <ShowDataDeletionResults />}
      </>
    );
  };

  const DevSection = () => {
    const [audit, setAudit] = useState(null);
    const [inserted, setInserted] = useState(false);

    const handleGenPipeline = async () => {
      const doc = await genCircuitAuditPipeline(
        circuit,
        createdBy.org,
        createdBy,
        createdAt
      );

      setAudit(doc);
    };

    const handleInsertPipeline = async () => {
      await createCircuitAudit(audit);

      setInserted(true);
    };

    const isPoleInspection = type === "poleInspection";
    const nothingToSee = !isPoleInspection;

    return (
      <CardBody>
        {isPoleInspection && (
          <Card>
            <CardHeader>Initial Audit Pipeline</CardHeader>
            <CardBody className="overflow-500">
              {!audit && (
                <Button
                  outline
                  color="danger"
                  size="sm"
                  onClick={(e) => handleGenPipeline()}
                >
                  Generate Audit Pipeline
                </Button>
              )}
              {audit && <Json data={audit} />}
            </CardBody>
            {audit && !inserted && (
              <CardFooter>
                <Button
                  outline
                  color="danger"
                  size="sm"
                  onClick={(e) => handleInsertPipeline()}
                >
                  Insert Audit Pipeline
                </Button>
              </CardFooter>
            )}
          </Card>
        )}
        {nothingToSee && (
          <Card>
            <CardBody>Nothing to see here...</CardBody>
          </Card>
        )}
      </CardBody>
    );
  };

  return (
    <div className="UploadedFile">
      <Card className="UploadCard">
        <CardHeader className={cardHeaderClass}>{fileName}</CardHeader>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggleTabs("1");
              }}
            >
              Upload
            </NavLink>
          </NavItem>
          {showErrors && errors && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  toggleTabs("2");
                }}
              >
                Errors <Badge color="danger">{errors.count}</Badge>
              </NavLink>
            </NavItem>
          )}
          {isPhotoFinal && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "3" })}
                onClick={() => {
                  toggleTabs("3");
                }}
              >
                Photos (.zip){" "}
                {isPhotos && <Badge color="dark">{support.photos.count}</Badge>}
              </NavLink>
            </NavItem>
          )}
          {showDataProcessing && !isProcessed && !isDuplicate && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "4" })}
                onClick={() => {
                  toggleTabs("4");
                }}
              >
                Transfer data
              </NavLink>
            </NavItem>
          )}
          {showOHPackage && isOhPackage && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "5" })}
                onClick={() => {
                  toggleTabs("5");
                }}
              >
                OH Package
              </NavLink>
            </NavItem>
          )}
          {showDataProcessing && uploadDbEvents.length > 0 && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "6" })}
                onClick={() => {
                  toggleTabs("6");
                }}
              >
                Data Processing
              </NavLink>
            </NavItem>
          )}
          {showToDev && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "7" })}
                onClick={() => {
                  toggleTabs("7");
                }}
              >
                Dev
              </NavLink>
            </NavItem>
          )}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <CardBody>
              {showToDev && (
                <CardText>
                  <b>Upload Id:</b> {uploadId}
                </CardText>
              )}
              <CardText>
                <b>Name:</b> {fileName}
              </CardText>
              <CardText>
                <b>Circuit:</b> {circuit}
              </CardText>
              <CardText>
                <b>Owner:</b> {createdBy.org}
              </CardText>
              <CardText>
                <b>Type:</b> {phraseToProperCase(type)}
              </CardText>
              <CardText>
                <b>Category:</b> {category}
              </CardText>
              {showErrors && (
                <CardText>
                  <b>Rules:</b> <RulesStatus />
                </CardText>
              )}
              <CardText>
                <b>Status:</b> {status}
              </CardText>
              <CardText>
                <b>Version:</b> {version}
              </CardText>
              {showDownloadOption && (
                <Button
                  outline
                  color="secondary"
                  size="sm"
                  onClick={(e) => getUrl(e, fileName)}
                >
                  <FontAwesomeIcon icon={faDownload} /> Download
                </Button>
              )}
              <Card
                body
                inverse
                style={{
                  backgroundColor: "#f7f7f7",
                  marginTop: "1.5rem"
                }}
              >
                <CardText>
                  <CreatedByField by={createdBy.name} at={createdAt} />
                </CardText>
                {updatedBy && (
                  <CardText>
                    <UpdatedByField by={updatedBy.name} at={updatedAt} />
                  </CardText>
                )}
              </Card>
            </CardBody>
          </TabPane>
          {showErrors && errors && (
            <TabPane tabId="2">
              <CardBody>
                <Card>
                  <CardBody className="ErrorFileCard">
                    <CardText>
                      <b>File name:</b> {errors.fileName}
                    </CardText>
                    <CardText>
                      <b>Count:</b> {errors.count}
                    </CardText>
                    {showDownloadOption && (
                      <Button
                        outline
                        color="secondary"
                        size="sm"
                        onClick={(e) => getUrl(e, errors.fileName)}
                      >
                        <FontAwesomeIcon icon={faDownload} /> Download
                      </Button>
                    )}
                  </CardBody>
                </Card>
              </CardBody>
            </TabPane>
          )}
          {isPhotoFinal && (
            <TabPane tabId="3">
              <CardBody>
                <Card>
                  <CardHeader>Compressed Files</CardHeader>
                  <CardBody>
                    {support.compressed.final && (
                      <Card className="SupportCard">
                        <CardBody className="SupportFileCard">
                          <CardText>
                            <b>File name:</b>{" "}
                            {support.compressed.final.fileName}
                          </CardText>
                          <CardText>
                            <b>Size:</b>{" "}
                            {prettyBytes(support.compressed.final.size)}
                          </CardText>
                          {showDownloadOption && (
                            <Button
                              outline
                              color="secondary"
                              size="sm"
                              onClick={(e) =>
                                getUrl(e, support.compressed.final.fileName)
                              }
                            >
                              <FontAwesomeIcon icon={faDownload} /> Download
                            </Button>
                          )}
                        </CardBody>
                      </Card>
                    )}
                  </CardBody>
                </Card>
                {support.needWork?.complete && (<Card>
                  <CardHeader>Photos of poles filtered for work</CardHeader>
                  <CardBody>
                    {support.compressed.final && (
                      <Card className="SupportCard">
                        <CardBody className="SupportFileCard">
                          <CardText>
                            <b>File name:</b>{" "}
                            {support.compressed.needWork.fileName}
                          </CardText>
                          <CardText>
                            <b>Size:</b>{" "}
                            {prettyBytes(support.compressed.needWork.size)}
                          </CardText>
                          {showDownloadOption && (
                            <Button
                              outline
                              color="secondary"
                              size="sm"
                              onClick={(e) =>
                                getUrl(e, support.compressed.needWork.fileName)
                              }
                            >
                              <FontAwesomeIcon icon={faDownload} /> Download
                            </Button>
                          )}
                        </CardBody>
                      </Card>
                      
                    )}
                  </CardBody>
                </Card>)}
                {support.errors && (
                  <Card className="mt-1rem">
                    <CardHeader>Image Mismatch Errors</CardHeader>
                    <CardBody>
                      <CardText>
                        <b>File name:</b> {support.errors.fileName}
                      </CardText>
                      <CardText>
                        <b>Mismatches:</b> {support.errors.mismatches}
                      </CardText>
                      {showDownloadOption && (
                        <Button
                          outline
                          color="secondary"
                          size="sm"
                          onClick={(e) => getUrl(e, support.errors.fileName)}
                        >
                          <FontAwesomeIcon icon={faDownload} /> Download
                        </Button>
                      )}
                    </CardBody>
                  </Card>
                )}
              </CardBody>
            </TabPane>
          )}
          {showDataProcessing && !isProcessed && datapoints === 0 && (
            <TabPane tabId="4">
              <CardBody>
                <Card>
                  <CardHeader>Transfer CSV data to database</CardHeader>
                  <CardBody>
                    {docsToInsert.length > 0 && <ReportDataTransferProgress />}
                    {docsToInsert.length === 0 && !foundDuplicatesPoles && (
                      <ProcessDataTransfer />
                    )}
                    {foundDuplicatesPoles && <ReportDuplicatePoles />}
                    {waitForPit && (
                      <Alert color="danger" className="mt-1rem mb-0rem">
                        PIT data must be transferred first
                      </Alert>
                    )}
                  </CardBody>
                </Card>
              </CardBody>
            </TabPane>
          )}
          {showDataProcessing && !isProcessed && datapoints > 0 && (
            <TabPane tabId="4">
              <CardBody>
                <Card>
                  <CardHeader>Delete records from database</CardHeader>
                  <CardBody>
                    {docsToDelete.length ? (
                      <ReportDeletionProgress />
                    ) : (
                      <ProcessDataDeletion />
                    )}
                  </CardBody>
                </Card>
              </CardBody>
            </TabPane>
          )}
          {showOHPackage && isOhPackage && (
            <TabPane tabId="5">
              <CardBody>
                <Card>
                  <CardHeader>Build Overhead Package CSV file</CardHeader>
                  <CardBody>
                    {!isDownload && (
                      <Button
                        outline
                        color="secondary"
                        size="sm"
                        onClick={(e) => handleBuildDownload(e, fileName)}
                        disabled={isLoading}
                      >
                        Build OH Package{" "}
                        {isLoading && <Spinner size="sm" color="dark" />}
                      </Button>
                    )}
                    {isDownload && <ShowDownloadLink />}
                  </CardBody>
                </Card>
              </CardBody>
            </TabPane>
          )}
          {showDataProcessing && uploadDbEvents.length > 0 && (
            <TabPane tabId="6">
              <CardBody>
                <Card>
                  <CardHeader>Data processing events</CardHeader>
                  <CardBody>
                    <ol>
                      {uploadDbEvents.map((event, i) => {
                        return (
                          <li key={i}>
                            On <Moment>{event.timestamp}</Moment>, {event.event}{" "}
                            of {event.docs} records
                          </li>
                        );
                      })}
                    </ol>
                  </CardBody>
                </Card>
              </CardBody>
            </TabPane>
          )}
          {showToDev && (
            <TabPane tabId="7">
              <DevSection />
            </TabPane>
          )}
        </TabContent>
      </Card>
    </div>
  );
};

export default UploadedFile;
